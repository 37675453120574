import ComingSoonImage from "@/components/coming-soon/ComingSoonImage";
import Form from "@/components/coming-soon/form/Form.vue";

export default {
	name: "Subheader",
	components: {
		ComingSoonImage,
		Form,
	},
};
