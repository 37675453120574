<template>
<div class="image-wrapper">
  <div class="shadow main-green--background" />
  <div class="image-background" />
  <div class="image-content white--background d-flex flex-column align-center justify-center pa-8 pa-md-13">
    <v-sheet
      :width="$vuetify.breakpoint.smAndDown ? '160' : '240'"
      :height="$vuetify.breakpoint.smAndDown ? '160' : '240'"
    >
      <v-img src="@/assets/images/one-transaction-logo.svg" />
    </v-sheet>
  </div>
</div>
</template>

<script>
export default {
  name: 'UnderConstructionImage'
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  position: relative;
  width: 500px;
  min-height: 680px;
  border-radius: 42px;
  box-shadow: 0px 20px 40px #2D343629;
  overflow: hidden;
}
.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.35;
}
.image-background {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}
.image-content {
  margin: 10px;
  width: calc(100% - 20px);
  min-height: calc(100% - 20px);
  position: absolute;
  border-radius: 40px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .image-wrapper {
    width: 100%;
    min-height: 444px;
    border-radius: 27px;
  }
  .image-content {
    margin: 5px;
    width: calc(100% - 10px);
    min-height: calc(100% - 10px);
    border-radius: 25px;
  }
}
</style>
